@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: rgb(224, 224, 224);
  }
  
p {
  padding-top:1em;
  text-align: left;
}
  .pageTitle {
font-size: 4em;
font-variant:small-caps;
margin-top: 0.6em;
padding-top: 0.6em;
margin-bottom: 0.4em;
padding-bottom: 0.4em;
  }


  .App {
    text-align: center;
  }
  
  .FooterText {
    color: gray;
    font-size: medium;
    text-align: center;
  }

  .pageTitle {
    font-size: 2rem;
    color:rgb(108 154 139 ) ;
  }

  .albumpic {
    padding:2px;
    border-radius: 6px  ;
  }
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  /* Ensure the images within the slider are responsive */
.slick-slide img {
  max-width: 100%;
  height: auto;
}

/* Adjust the positioning of dots */
.slick-dots {
  bottom: 0px;
  margin-bottom: 5px;
  padding-bottom:5px;
}

/* Use media queries to adjust the slider container */
@media (max-width: 768px) {
  .slider-container {
    width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .slider-container {
    width: 50%;
    margin: 0 auto;
  }
}
